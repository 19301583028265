import React from 'react';
import {graphql, useStaticQuery, Link} from 'gatsby';
import css from '../styles/blog/recent_posts.module.scss';

export default function RecentPosts() {
    const {allMarkdownRemark} = useStaticQuery(
        graphql`
            query {
                allMarkdownRemark(
                    sort: {
                        fields: frontmatter___date,
                        order: DESC
                    },
                    limit: 10) {
                        nodes {
                            id
                            frontmatter {
                                title
                            }
                            fields {
                                slug
                            }
                        }
                }
            }
        `
    )
    return (
        <div className={css.recentPosts}>
            <h3>Recent Blog Posts:</h3>
            <ul>
            {
                allMarkdownRemark.nodes.map(node => {
                    return (
                        <li key={node.id}>
                            <Link to={node.fields.slug}>
                                {node.frontmatter.title}
                            </Link>
                        </li>
                    )
                })
            }
            </ul>
        </div>
    )
}